<template>
  <!-- 登录页 -->
  <div class="home" style="display: flex">
    <div class="container">
      <div class="loginForms">
        <img class="loginImages" />
        <div class="loginTitle">
          <div class="titleTop">欢迎使用</div>

          <el-form
            v-if="loginBool"
            prop="name"
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item prop="username">
              <!-- <el-input
              placeholder="请输入账号"
              type="text"
              v-model="ruleForm.username"
              autocomplete="off"
              prefix-icon="el-icon-s-custom"
              style="border: none;outline: none;"
            ></el-input> -->
              <input
                v-model="ruleForm.username"
                type="text"
                placeholder="请输入账号"
                style="
                  border: none;
                  font-size: 18px;
                  border-bottom: 1px solid #d8d8d8;
                  padding: 20px 0;
                  width: 100%;
                  outline: none;
                "
              />
            </el-form-item>
            <el-form-item prop="checkPass">
              <!-- <el-input
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              type="password"
              v-model="ruleForm.checkPass"
              autocomplete="off"
            ></el-input> -->
              <input
                v-model="ruleForm.checkPass"
                type="text"
                placeholder="请输入密码"
                style="
                  border: none;
                  font-size: 18px;
                  border-bottom: 1px solid #d8d8d8;
                  padding: 20px 0;
                  width: 100%;
                  outline: none;
                "
              />
            </el-form-item>
          </el-form>

          <!-- <div style="display: flex;justify-content: space-between;align-items: center;">
          <div>
            <el-checkbox text-color="#01B37C" v-model="rememberPwdCheck">记住账号</el-checkbox>
          </div>
          <div style="font-size: 16px;color: #01B37C;cursor: pointer;">忘记密码？</div>
        </div> -->

          <div @click="LoginFn" class="loginBut">登录</div>
          <!-- <el-button @click="LoginFn" type="primary">登录</el-button> -->
        </div>
      </div>
      <div class="beian">
        <a href="https://beian.miit.gov.cn/">京ICP备 2023010830号</a>
        <p>版权所有：孔圣昉(北京)资讯有限公司</p>
      </div>
    </div>

    <div v-if="false" class="login">
      <div class="userLogin">
        <h2>
          星点文字识别
          <p style="font-size: 15px">www.xdwzsb.com</p>
        </h2>

        <el-form
          v-if="loginBool"
          prop="name"
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="username">
            <el-input
              placeholder="请输入账号"
              type="text"
              v-model="ruleForm.username"
              autocomplete="off"
              prefix-icon="el-icon-s-custom"
            ></el-input>
          </el-form-item>
          <el-form-item prop="checkPass">
            <el-input
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              type="password"
              v-model="ruleForm.checkPass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>

        <el-form
          v-else
          prop="name"
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item>
            <el-input
              placeholder="请输入邮箱"
              type="text"
              v-model="mailbox"
              autocomplete="off"
              prefix-icon=""
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-input
              placeholder="请输入密码"
              type="password"
              v-model="mailboxPass"
              autocomplete="off"
              prefix-icon=""
            ></el-input>
          </el-form-item>

          <div class="buttonItem">
            <input v-model="vercode" type="text" placeholder="输入验证码" />
            <div class="red sendCode" v-if="show1" @click="sendMessage">
              {{ btnText }}
            </div>
            <div class="red sendCode" v-if="show2">{{ timeDown }}秒后重试</div>
          </div>
        </el-form>
        <div class="register" v-if="loginBool" style="color: black">
          还没有账号？<el-button @click="registerFn" type="text"
            >点击注册</el-button
          >
          <el-divider direction="vertical"></el-divider>
          忘记密码？<el-button @click="findPassword" type="text"
            >找回密码</el-button
          >
        </div>
        <!--<div class="register" @click="forgetPwd" v-if="loginBool">-->
        <!--  还没有账号？点击注册-->
        <!--</div>-->
        <div class="register" v-else>
          已有账号？<el-button @click="registerFn" type="text"
            >点击登录</el-button
          >
        </div>

        <el-button
          type="primary"
          style="width: 30%; margin-bottom: 70px"
          @click="LoginFn"
          v-if="loginBool"
        >
          登录
        </el-button>
        <el-button
          type="primary"
          style="width: 30%; margin-bottom: 70px"
          @click="ResTrueFn"
          v-else
        >
          注册
        </el-button>
        <div class="beians">
          <a href="https://beian.miit.gov.cn/">京ICP备 2023010830号</a>
        </div>
        <!-- <button class="submit">提交</button> -->
      </div>
    </div>
    <!--添加服务器-->
    <el-dialog
      title="重置密码(十分钟内有效)"
      :visible.sync="dialogVisible"
      width="35%"
    >
      <el-form ref="form" :model="Forget" label-width="80px">
        <el-form-item label="邮箱">
          <el-input v-model="Forget.email"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="Sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import LandingPage from "@/components/LandingPage.vue";
import { RegisterFn, SendRegCodeFn, getLoginFn, Login } from "@/request/api.js";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback("账号不能为空");
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      rememberPwdCheck: false,
      // 验证码
      vercode: "",
      btnDisabled: false,
      btnText: "获取验证码",
      mailbox: "",
      mailboxPass: "",
      //
      loginBool: true,
      ruleForm: {
        username: "",
        checkPass: "",
      },
      rules: {
        username: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },

      show1: true,
      show2: false,
      timeDown: 60,

      dialogVisible: false,

      Forget: {
        type: 1,
      },
    };
  },

  methods: {
    registerFn() {
      this.loginBool = !this.loginBool;
    },
    //找回密码
    findPassword() {
      this.dialogVisible = true;
    },
    Sure() {
      SendRegCodeFn(this.Forget).then((res) => {
        if (res.Code === 2000) {
          this.$message({
            message:
              "操作成功，新密码已发送至您的邮箱，请注意查收，并尽快更改密码",
            type: "success",
          });
        } else {
          this.$message({
            message: res.Message,
            type: "error",
          });
        }
      });
    },

    // 真正的注册
    ResTrueFn() {
      RegisterFn({
        email: this.mailbox,
        password: this.mailboxPass,
        code: Number(this.vercode),
      }).then((res) => {
        console.log(res);
        if (res.Code === 2000) {
          localStorage.setItem("token", res.Data.Token);
          this.$router.push("/home/Index");
          this.$message({
            message: "注册成功",
            type: "success",
          });
        } else if (res.Code === 4000) {
          this.$message({
            message: res.Message,
            type: "warning",
          });
        }
      });
    },

    LoginFn() {
      console.log(this.ruleForm);
      Login({
        userName: this.ruleForm.username,
        pwd: this.ruleForm.checkPass,
      }).then((res) => {
        console.log(res);
        if (res.Code === 2000) {
          console.log(res.Data.Token);
          localStorage.setItem("token", res.Data.Token);
          localStorage.setItem("TokenData", JSON.stringify(res.Data));
          let storageTime = new Date().getTime();
          localStorage.setItem("storageTime", storageTime);
          this.$message({
            message: "登录成功",
            type: "success",
          });
          if (res.Data.Role == "admin") {
            this.$router.push("/home/ApiData");
          }
          if (res.Data.Role == "teacher") {
            this.$router.push("/home/Index");
          }
        } else if (res.Code === 4000) {
          localStorage.clear();
          this.$message({
            message: res.Message,
            type: "warning",
          });
        } else if (res.Code === 4001) {
          this.$message({
            message: res.Message,
            type: "warning",
          });
        }
      });
    },

    timeDownFun() {
      this.timer = setInterval(() => {
        this.timeDown--;
        if (this.timeDown === 0) {
          this.timeDown = 60;
          this.show1 = true;
          this.show2 = false;
          clearInterval(this.timer);
        }
      }, 1000);
    },

    // 验证码倒计时
    sendMessage() {
      this.show1 = false;
      this.show2 = true;
      this.timeDownFun();
      if (this.btnDisabled) {
        return;
      }
      // this.getSecond(60);
      SendRegCodeFn({
        email: this.mailbox,
        type: 0,
      }).then((res) => {
        console.log(res);
        if (res.Code === 2000) {
        } else if (res.Code === 4000) {
          this.$message({
            message: res.Message,
            type: "warning",
          });
        }
      });
    },
    //发送验证码
    getSecond(wait) {
      let _this = this;
      let _wait = wait;
      if (wait === 0) {
        this.btnDisabled = false;
        this.btnText = "获取验证码";
        wait = _wait;
      } else {
        this.btnDisabled = true;
        this.btnText = "验证码(" + wait + "s)";
        wait--;
        setTimeout(function () {
          _this.getSecond(wait);
        }, 1000);
      }
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      this.$router.push("/home/Index");
    }
  },

  name: "Home",
  components: {
    // LandingPage,
  },
};
</script>

<style lang="less" scoped>
.login {
  background-image: linear-gradient(160deg, #db88a9 20%, #bce18f 80%);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userLogin {
  box-shadow: 1px 1px 1px #acacac;
  background-color: white;
  text-align: center;
  width: 500px;
  border-radius: 30px;
  /* background-color: #fff; */
}
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff0c;
  .loginForms {
    width: 90%;
    height: 80%;
    display: flex;
    .loginImages {
      width: 40%;
      height: 100%;
      background: url(../../assets/img/教育海报.png) no-repeat;
      background-size: 100% 100%;
    }
    .loginTitle {
      width: 60%;
      height: 100%;
      background-color: #fff;
      padding: 30px 0;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .titleTop {
        width: 80%;
        height: 48px;
        text-align: center;
        line-height: 48px;
        color: #333;
        font-size: 30px;
        font-weight: 600;
      }
      .demo-ruleForm {
        width: 80%;
        height: 300px;
        margin: 0;
        box-sizing: border-box;
        padding-top: 30px;
      }
      .loginBut {
        cursor: pointer;
        width: 80%;
        height: 60px;
        background-color: #01b37c;
        color: #fff;
        text-align: center;
        line-height: 60px;
        font-size: 20px;
        border-radius: 8px;
      }
    }
  }
  .beian {
    width: 100%;
    height: 10%;
    box-sizing: border-box;
    padding: 10px 0;
    background-color: #cccccc15;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    a {
      text-decoration: none;
    }
  }
}
.beians {
  // margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
h2 {
  /* text-align: center; */
  color: #000000;
  margin-bottom: 20px;
  margin-top: 100px;
}
.submit {
  width: 30%;
  background-color: #409eff;
  height: 2.2rem;
  border: none;
  border-radius: 0.2rem;
  color: #fff;
}
.demo-ruleForm {
  /* padding: 30px; */
  width: 300px;
  margin: auto;
  /* margin-left: 70px; */
}
.buttonItem {
  margin: 15px 0px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 95%;
  input {
    height: 45px;
    font-size: 1rem;
    padding-left: 10px;
    border: 0;
    outline: none;
  }

  .sendCode {
    border: 0;
    outline: none;
    background-color: #fff;
    cursor: pointer;
  }
}
.register {
  color: #409eff;
  position: relative;
  /* left: -8%; */
  margin-bottom: 20px;
  cursor: pointer;
}
</style>
