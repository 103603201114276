<template>
  <div class="bigbox">
    <div style="display: flex; padding: 0px 0; align-items: center">
      <!-- <el-select
      clearable
        v-model="form2.Year"
        style="width: 120px; margin-right: 10px"
        placeholder="选择岁"
      >
        <el-option
          v-for="item in YearData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
      clearable
        v-model="form2.Month"
        style="width: 120px; margin-right: 10px"
        placeholder="选择月"
      >
        <el-option
          v-for="item in MonthData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
      clearable
        v-model="form2.Week"
        style="width: 120px; margin-right: 10px"
        placeholder="选择周"
      >
        <el-option
          v-for="item in WeekData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select> -->


      <el-select
      v-if="false"
      clearable
        v-model="form2.type"
        style="width: 180px; margin-right: 10px"
        placeholder="请选择分类"
      >
        <el-option
          v-for="item in tableData"
          :key="item.Id"
          :label="item.label"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <!-- <span style="margin-right: 10px">至</span>
      <el-select
        v-model="form2.endYear"
        style="width: 120px; margin-right: 10px"
        placeholder="选择岁"
      >
        <el-option
          v-for="item in YearData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.endMonth"
        style="width: 120px; margin-right: 10px"
        placeholder="选择月"
      >
        <el-option
          v-for="item in MonthData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.endWeek"
        style="width: 120px; margin-right: 10px"
        placeholder="选择周"
      >
        <el-option
          v-for="item in WeekData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="form2.type"
        style="margin-left: 20px"
        placeholder="请选择分类名称"
      >
        <el-option
          v-for="item in WorkTypeData"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select> -->

      <div
      v-if="false"
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #01b37c;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          cursor: pointer;
        "
        @click="SearchWorkLimitPageFn"
      >
        搜索
      </div>
      <div
      v-if="false"
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #fff;
          color: #01b37c;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          border: 1px solid #01b37c;
          box-sizing: border-box;
          cursor: pointer;
        "
        @click="resettingFn"
      >
        重置
      </div>
      <div
        style="
          width: 110px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #01b37c;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 0px;
          cursor: pointer;
        "
        @click="openAddClassFn"
      >
        新增分类
      </div>
    </div>

    <div style="width: 100%; margin-top: 20px">
      <el-table
        :data="tableData"
        :header-cell-style="getRowClass"
        style="width: 100%; background-color: #fff"
        :row-style="{ height: '60px' }"
      >
        <el-table-column label="序号" prop="CardId">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column label="分类名称" prop="label" />
        <el-table-column v-if="false" label="分类名称" prop="WorkTypeName">
          <!-- <template slot-scope="scope">
            {{scope.row.WorkType}}
            <span v-if="scope.row.WorkType == 1">基础课</span>
            <span v-if="scope.row.WorkType == 2">专项课</span>
            <span v-if="scope.row.WorkType == 3">试听课</span>
          </template> -->
        </el-table-column>
        <el-table-column v-if="false" label="适合学生批次" prop="Between" />
        <el-table-column v-if="false" label="上传时间" prop="UpLoadTime" />
        <!-- <el-table-column label="课程教案" prop="TeachPlanName" /> -->
        <!-- <el-table-column label="课次" prop="ClassCount" /> -->
        <el-table-column v-if="false" label="报名学生" prop="StudentCount" />
        <el-table-column label="操作">
          <template #default="scope">
            <!-- <el-popconfirm
                style="margin-left: 10px"
                confirm-button-text='删除'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                title="确认删除吗？"
                @confirm="deleteKeys"
            > -->
            <span
              @click="openEditClassFn(scope.row)"
              style="font-size: 14px; color: #007aff; cursor: pointer"
              >编辑</span
            >
            <!-- <span
              @click="openEditWorkFn(scope.row)"
              style="font-size: 14px; color: #007aff; cursor: pointer"
              >编辑</span
            > -->
            <span
              @click="DeleteTeachPlanFn(scope.row)"
              style="
                font-size: 14px;
                color: #ee6e6e;
                cursor: pointer;
                margin-left: 10px;
              "
              >删除</span
            >
            <!-- <span
              @click="DeleteWorkFn(scope.row)"
              style="
                font-size: 14px;
                color: #ee6e6e;
                cursor: pointer;
                margin-left: 10px;
              "
              >删除</span
            > -->

            <!-- <el-button slot="reference" type="danger" @click="handleDelete(scope)">删除</el-button> -->
            <!-- </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>

      <div v-if="false">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageindex"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="AllCount"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增课程 -->
    <el-dialog v-if="false" :title="title" :visible.sync="dialogVisible1" width="800px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <el-form ref="form" :model="form">
          <div>
            <div style="display: flex; width: 100%; align-items: center">
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                分类名称
              </div>
              <el-input
                v-model="form.WorkName"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>

            <div style="display: flex; width: 100%; align-items: center;margin-top: 10px;">
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                报名人数
              </div>
              <el-input
                v-model="form.StudentCount"
                style="flex: 1"
                placeholder="请输入"
              />
            </div>

            <!-- 课程分类 -->
            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                课程分类
              </div>
              <el-select
              clearable
                v-model="form.WorkType"
                style="flex: 1"
                placeholder="请选择课程分类"
              >
                <el-option
                  v-for="item in WorkTypeData"
                  :key="item.value"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </div>

            <!-- 学生批次 -->
            <div
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                学生批次
              </div>
              <el-select
              clearable
                style="flex: 1"
                v-model="form.Year"
                placeholder="选择年"
              >
                <el-option
                  v-for="item in YearData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <el-select
              clearable
                style="flex: 1"
                v-model="form.Month"
                placeholder="选择月"
              >
                <el-option
                  v-for="item in MonthData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <el-select
              clearable
                style="flex: 1"
                v-model="form.Week"
                placeholder="选择周"
              >
                <el-option
                  v-for="item in WeekData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>

              <span
              v-if="false"
                @click="SearchTeachAnFn()"
                style="margin-left: 5px; color: #01b37c; cursor: pointer"
                >查询</span
              >
            </div>

            <!-- 课程教案 -->
            <div
            v-if="false"
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 10px;
              "
            >
              <div style="font-size: 16px; font-size: 16px; width: 80px">
                课程教案
              </div>
              <el-select
              clearable
                v-model="TeachValue"
                style="flex: 1"
                placeholder="请选择课程教案"
              >
                <el-option
                  v-for="item in TeachAnData"
                  :key="item.value"
                  :label="item.PlanName"
                  :value="item.PlanId"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div
            style="
              margin-top: 40px;
              display: flex;
              width: 100%;
              justify-content: center;
            "
          >
            <div
              @click="offAddWorkFn"
              style="
                width: 120px;
                height: 40px;
                border-radius: 5px;
                line-height: 40px;
                text-align: center;
                color: #01b37c;
                background-color: #fff;
                border: 1px solid #01b37c;
                box-sizing: border-box;
                cursor: pointer;
              "
            >
              取消
            </div>

            <div
            v-if="title=='新增分类'"
              style="
                width: 120px;
                height: 40px;
                border-radius: 5px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                background-color: #01b37c;
                margin-left: 20px;
                cursor: pointer;
              "
              @click="AddWorkFn()"
            >
              确定
            </div>

            <div
            v-if="title=='编辑分类'"
              style="
                width: 120px;
                height: 40px;
                border-radius: 5px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                background-color: #01b37c;
                margin-left: 20px;
                cursor: pointer;
              "
              @click="UpdateWorkFn()"
            >
              确定
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>

    <!-- 新增分类 -->
    <el-dialog title="新增分类" :visible.sync="dialogVisible1" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              分类名称
            </div>

            <el-input
              v-model="form.typeName"
              placeholder="请输入分类名称"
              style="flex: 1"
            />

        
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offAddClassFn"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="AddTeachPlanFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>

     <!-- 修改分类 教案 -->
     <el-dialog title="修改分类" :visible.sync="dialogVisible2" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <div style="display: flex; width: 100%; align-items: center">
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              分类名称
            </div>

            <el-input
              v-model="form.typeName"
              placeholder="请输入分类名称"
              style="flex: 1"
            />
            <!-- 
            <el-select style="flex: 1" v-model="form.typeName" placeholder="选择年">
              <el-option
                v-for="item in YearData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-select
              style="flex: 1"
              v-model="form.Month"
              placeholder="选择月"
            >
              <el-option
                v-for="item in MonthData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-select style="flex: 1" v-model="form.Week" placeholder="选择周">
              <el-option
                v-for="item in WeekData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select> -->
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offEditClassFn"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="UpdateTeachPlanFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import {
  GetAllWorkLimitPage,
  GetAllTeachPlan,
  GetRealTeachPlanBySome,
  AddWork,
  UpdateWork,
  DeleteWork,
  SearchWorkLimitPage,
  AddTeachPlan,
  UpdateTeachPlan,
  DeleteTeachPlan,
} from "@/request/api.js";
import LoginVue from "../Login/Login.vue";
export default {
  data() {
    return {
      title: "新增分类",
      tableData: [],
      AllCount: 0,
      pageindex: 1,
      pagesize: 10,
      total: 0,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      weekIndex: 0,
      dialogVisible1: false,
      dialogVisible2: false,
      form: {
        typeName:'',
        Id: 0,
        WorkName: "", //
        WorkType: "", //
        UpLoadTime: "",
        TeachPlan: 0,
        ClassCount: 0,
        StudentCount: '',
        TeachPlanName: "",
        Year: "",
        Month: "",
        Week: "",
        // EndYear: "",
        // EndMonth: "",
        // EndWeek: "",
      },
      TeachValue: "", //
      form2: {
       Year: "",
       Month: "",
       Week: "",
      type: "",
      teacherName:'',
      },
      WeekData: [
        {
          Id: 1,
          Name: "第一周",
        },
        {
          Id: 2,
          Name: "第二周",
        },
        {
          Id: 3,
          Name: "第三周",
        },
        {
          Id: 4,
          Name: "第四周",
        },
      ],
      YearData: [],
      MonthData: [],
      WorkTypeData: [
        {
          Id: 1,
          Name: "基础课",
        },
        {
          Id: 2,
          Name: "专项课",
        },
        {
          Id: 3,
          Name: "试听课",
        },
      ],
      searchCheck: false, //
      TeachAnData: [], //根据条件获取课程教案
      PlanData:[],
    };
  },

  watch: {
    // [form.StartYear](val){
    //   this.form.TeachValue = '';
    // },
    // [form.StartMonth](val){
    //   this.form.TeachValue = '';
    // },
    // [form.StartWeek](val){
    //   this.form.TeachValue = '';
    // },
    // [form.EndYear](val){
    //   this.form.TeachValue = '';
    // },
    // [form.EndMonth](val){
    //   this.form.TeachValue = '';
    // },
    // [form.EndWeek](val){
    //   this.form.TeachValue = '';
    // },
    'form2.Year'(e){
      if(e!==''){
        // if(this.form2.Month!==''&&this.form2.Week!==''){
          this.SearchWorkLimitPageFn();
        // }
      }
    },
    'form2.Month'(e){
      if(e!==''){
        // if(this.form2.Year!==''&&this.form2.Week!==''){
          this.SearchWorkLimitPageFn();
        // }
      }
    },
    'form2.Week'(e){
      if(e!==''){
        // if(this.form2.Year!==''&&this.form2.Month!==''){
          this.SearchWorkLimitPageFn();
        // }
      }
    },
    'form2.type'(e){
      if(e!=''){
        this.SearchWorkLimitPageFn();
      }
    }
  },
created() {
    // this.GetAllWorkLimitPageFn(); //获取所有课程
    // this.GetAllTeachPlanFn(); //获取所有教案
    this.GetAllTeachPlanFn();

    let arr = [];
    let arr2 = [];
    for (let i = 0; i < 7; i++) {
      arr.push({
        Id: i,
        Name: i + "岁",
      });
    }
    for (let i = 1; i < 13; i++) {
      arr2.push({
        Id: i,
        Name: i + "月",
      });
    }
    this.YearData = arr;
    this.MonthData = arr2;
  },
  methods: {

     //获取所有分类
     GetAllTeachPlanFn() {
      let that = this;
      GetAllTeachPlan().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有教案分类==>", res.Data);
          // that.teachData = res.Data;
          that.tableData = res.Data;
        }
      });
    },

     //打开新增分类
     openAddClassFn() {
        this.dialogVisible1 = true
        this.form.typeName = '';
    },

    offAddClassFn() {
      this.form.typeName = '';
      this.dialogVisible1 = false;
    },

    //新建分类
    AddTeachPlanFn() {
      let that = this;
      console.log("this.from==>", this.form);
      if (this.form.typeName === "") {
        this.$message({
          message: "请填写分类名称",
          type: "error",
        });
        return;
      }

      AddTeachPlan({
        fatherId: 0,
        label: that.form.typeName,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("新建分类=>", res.Data);
          this.dialogVisible1 = false;
          this.$message({
            message: "添加成功",
            type: "success",
          });

          that.form.typeName = ''

          that.GetAllTeachPlanFn();
        } else {
          this.$message({
            message: res.data.Message,
            type: "error",
          });
        }
      });
    },

    //打开修改分类
    openEditClassFn(row) {
      // console.log("row==>", row);
      this.form.chooseTypeId = row.Id;
      this.dialogVisible2 = true;
      this.form.typeName = row.label
    },
    offEditClassFn() {
      this.dialogVisible2 = false;
    },

     //修改分类名称
     UpdateTeachPlanFn() {
      let that = this;

      console.log("that.form6==>", that.form);

      UpdateTeachPlan(
        { Id: that.form.chooseTypeId },
        {
          fatherId: 0,
          label: that.form.typeName,
        }
      ).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("修改分类==>", res.Data);
          that.$message({
            message: "修改成功",
            type: "success",
          });
          that.GetAllTeachPlanFn(); //获取所有教案

          that.dialogVisible2 = false;

          that.form.typeName = '';
        } else {
          this.$message({
            message: res.data.Message,
            type: "error",
          });
        }
      });
    },

    // 删除教案、分类
    DeleteTeachPlanFn(row) {
      let that = this;

      that
        .$confirm("确定删除该分类?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          DeleteTeachPlan({
            Id: row.Id,
          }).then((res) => {
            console.log(" 管理员删除分类==>", res);
            if (res.Code === 2000) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.GetAllTeachPlanFn();
            } else {
              that.$message({
                type: "error",
                message: res.Message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //教案
    // 表头
    getRowClass({ row, column, rowIndex, columnIndex }) {
      // return "background:#50a0ff;color:#fff;font-size:16px;color:#fff";
      return "background:#F8FCFE;color:#fff;font-size:16px;color:#333";
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      // this.GetAllWorkLimitPageFn();
      if (this.searchCheck == true) {
        this.SearchWorkLimitPageFn();
      } else {
        this.GetAllWorkLimitPageFn();
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageindex = val;
      // this.GetAllWorkLimitPageFn();
      if (this.searchCheck == true) {
        this.SearchWorkLimitPageFn();
      } else {
        this.GetAllWorkLimitPageFn();
      }
    },

    //打开新增课程
    openAddWorkFn() {
      this.title = "新增分类";
      this.dialogVisible1 = true;
    },
    offAddWorkFn() {
      this.dialogVisible1 = false;
    },

    //打开编辑课程
    openEditWorkFn(row) {
      console.log("row-->", row);
      this.title = "编辑分类";
      this.form = row;
      this.dialogVisible1 = true;
      // this.GetRealTeachPlanBySomeFn(row); //根据条件获取课程教案
    },

    //查询 条件的教案
    SearchTeachAnFn() {
      this.TeachAnData = [];
      // this.GetRealTeachPlanBySomeFn(); //根据条件获取课程教案
    },

    // 添加课程
    AddWorkFn() {
      let that = this;
      console.log("that.form==>", that.form);
      if (that.form.WorkName == "") {
        that.$message({
          message: "请输入课程名称",
          type: "warning",
        });
        return;
      }
      if(that.form.StudentCount == ''){
        that.$message({
          message: "请输入学生人数",
          type: "warning",
        });
        return;
      }
      if (that.form.WorkType == "") {
        that.$message({
          message: "请选择分类名称",
          type: "warning",
        });
        return;
      }
      if (that.form.Year === "") {
        that.$message({
          message: "请选择开始年份",
          type: "warning",
        });
        return;
      }
      if (that.form.Month === "") {
        that.$message({
          message: "请选择开始月份",
          type: "warning",
        });
        return;
      }
      if (that.form.Week === "") {
        that.$message({
          message: "请选择开始周数",
          type: "warning",
        });
        return;
      }
      // if (that.form.EndYear == "") {
      //   that.$message({
      //     message: "请选择结束年份",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.form.EndMonth == "") {
      //   that.$message({
      //     message: "请选择结束月份",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.form.EndWeek == "") {
      //   that.$message({
      //     message: "请选择结束周数",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.TeachValue == "") {
      //   that.$message({
      //     message: "请选择教案",
      //     type: "warning",
      //   });
      //   return;
      // }

      // let index = that.teachData.findIndex((item) => {
      //   return item.Id == that.TeachValue;
      // });
      // if (index != -1) {
      //   that.form.TeachPlanName = that.teachData[index].TeachPlanName;
      // }
      AddWork({
        Id: 0,
        WorkName: that.form.WorkName,
        WorkType: that.form.WorkType,
        Year: that.form.Year,
        Month: that.form.Month,
        Week: that.form.Week,
        // EndYear: that.form.EndYear,
        // EndMonth: that.form.EndMonth,
        // EndWeek: that.form.EndWeek,
        UpLoadTime: "2023-03-06T01:00:45.535Z",
        // TeachPlan: that.TeachValue,
        ClassCount: 0,
        StudentCount: that.form.StudentCount,
        TeachPlanName: that.form.TeachPlanName,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          that.$message({
            message: "添加成功",
            type: "success",
          });
          that.dialogVisible1 = false;
          that.form.WorkName = "";
          that.form.WorkType = "";
          that.form.Year = "";
          that.form.Month = "";
          that.form.Week = "";
          // that.form.EndYear = "";
          // that.form.EndMonth = "";
          // that.form.EndWeek = "";
          that.form.StudentCount = "";
          that.TeachValue = "";
          that.GetAllWorkLimitPageFn();
        } else {
          that.$message({
            message: "添加失败",
            type: "error",
          });
        }
      });
    },

    // 修改课程
    UpdateWorkFn() {
      let that = this;
      console.log("that.form==>", that.form);
      if (that.form.WorkName == "") {
        that.$message({
          message: "请输入课程名称",
          type: "warning",
        });
        return;
      }
      if(that.form.StudentCount == ''){
        that.$message({
          message: "请输入学生人数",
          type: "warning",
        });
        return;
      }
      if (that.form.WorkType == "") {
        that.$message({
          message: "请选择分类名称",
          type: "warning",
        });
        return;
      }
      if (that.form.Year === "") {
        that.$message({
          message: "请选择开始年份",
          type: "warning",
        });
        return;
      }
      if (that.form.Month === "") {
        that.$message({
          message: "请选择开始月份",
          type: "warning",
        });
        return;
      }
      if (that.form.Week === "") {
        that.$message({
          message: "请选择开始周数",
          type: "warning",
        });
        return;
      }
      // if (that.form.EndYear == "") {
      //   that.$message({
      //     message: "请选择结束年份",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.form.EndMonth == "") {
      //   that.$message({
      //     message: "请选择结束月份",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.form.EndWeek == "") {
      //   that.$message({
      //     message: "请选择结束周数",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.TeachValue == "") {
      //   that.$message({
      //     message: "请选择教案",
      //     type: "warning",
      //   });
      //   return;
      // }


      // let index = that.teachData.findIndex((item) => {
      //   return item.Id == that.TeachValue;
      // });
      // if (index != -1) {
      //   that.form.TeachPlanName = that.teachData[index].TeachPlanName;
      // }
      if(that.form.WorkType=='基础课'){
        that.form.WorkType=1;
      }
      else if(that.form.WorkType=='专项课'){
        that.form.WorkType=2;
      }
      else if(that.form.WorkType=='试听课'){
        that.form.WorkType=3;
      }
      UpdateWork({
        Id: that.form.Id,
        WorkName: that.form.WorkName,
        WorkType: that.form.WorkType,
        Year: that.form.Year,
        Month: that.form.Month,
        Week: that.form.Week,
        // EndYear: that.form.EndYear,
        // EndMonth: that.form.EndMonth,
        // EndWeek: that.form.EndWeek,
        UpLoadTime: that.form.UpLoadTime,
        // TeachPlan: that.TeachValue,
        ClassCount: that.form.ClassCount,
        StudentCount: Number(that.form.StudentCount),
        // TeachPlanName: that.form.TeachPlanName,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          that.$message({
            message: "修改成功",
            type: "success",
          });
          that.dialogVisible1 = false;
          that.form.WorkName = "";
          that.form.WorkType = "";
          that.form.Year = "";
          that.form.Month = "";
          that.form.Week = "";
          // that.form.EndYear = "";
          // that.form.EndMonth = "";
          // that.form.EndWeek = "";
          that.form.Id = "";
          that.form.UpLoadTime = "";
          that.form.ClassCount = "";
          that.form.StudentCount = "";
          that.TeachValue = "";
          that.GetAllWorkLimitPageFn();
        } else {
          that.$message({
            message: res.Message,
            type: "error",
          });
        }
      });
    },

    //删除课程
    DeleteWorkFn(row){
      let that = this;
      that
        .$confirm("确定删除该课程信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          console.log("666");
          DeleteWork({
            Id: row.Id,
          }).then((res) => {
            console.log(" 管理员删除课程==>", res);
            if (res.Code === 2000) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.GetAllWorkLimitPageFn();
            } else {
              that.$message({
                type: "error",
                message: res.Message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

      //课程列表搜索
    SearchWorkLimitPageFn() {
      let that = this;
      console.log("课程列表搜索==>", that.form2);
      // if (that.form2.Year === "") {
      //   that.$message({
      //     message: "请选择开始年龄",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.form2.Month === "") {
      //   that.$message({
      //     message: "请选择开始月份",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (that.form2.Week === "") {
      //   that.$message({
      //     message: "请选择开始周数",
      //     type: "warning",
      //   });
      //   return;
      // }
   
      SearchWorkLimitPage({
        Year: that.form2.Year===''?'-1':that.form2.Year,
        Month: that.form2.Month===''?'-1':that.form2.Month,
        Week: that.form2.Week===''?'-1':that.form2.Week,
        type: that.form2.type==''?'-1':that.form2.type,
        pageindex: that.pageindex,
        pagesize: that.pagesize,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("课程列表搜索==>", res.Data.Data);
          console.log("课程列表搜索数量==>", res.Data.AllCount);
          that.searchCheck = true;

          

          for (let i = 0; i < res.Data.Data.length; i++) {
            const element = res.Data.Data[i];
            element.Between =
                element.Year +
                "岁" +
                element.Month +
                "月" +
                element.Week +
                "周"
                // element.StartYear +
                // "岁" +
                // element.StartMonth +
                // "月" +
                // element.StartWeek +
                // "周" +
                // "-" +
                // element.EndYear +
                // "年" +
                // element.EndMonth +
                // "月" +
                // element.EndWeek +
                // "周";

          //   element.WorkTime =
          //     element.WorkTime.split(" ")[0] +
          //     " " +
          //     String(
          //       String(
          //         element.StartHour < 10
          //           ? "0" + element.StartHour
          //           : element.StartHour
          //       ) +
          //         ":" +
          //         String(
          //           element.StartMinute < 10
          //             ? "0" + element.StartMinute
          //             : element.StartMinute
          //         ) +
          //         "-" +
          //         String(
          //           element.EndHour < 10
          //             ? "0" + element.EndHour
          //             : element.EndHour
          //         ) +
          //         ":" +
          //         String(
          //           element.EndMinute < 10
          //             ? "0" + element.EndMinute
          //             : element.EndMinute
          //         )
          //     );
          }

          that.tableData = res.Data.Data;
          that.AllCount = res.Data.AllCount;
        } else {
          that.$message.error(res.Message);
        }
      });
    },

     //搜索重置
     resettingFn(){
      this.form2 = {
        Year: "",
        Month: "",
        Week: "",
        type: "",
        teacherName:'',
      },
      this.searchCheck==false;
      this.pageindex = 1;
      this.pagesize = 10;
      this.GetAllWorkLimitPageFn();
    },

    //获取所有课程
    GetAllWorkLimitPageFn() {
      let that = this;
      console.log("that.pageindex==>", that.pageindex);
      console.log("that.pagesize==>", that.pagesize);
      GetAllWorkLimitPage({
        pageindex: that.pageindex,
        pagesize: that.pagesize,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          that.searchCheck = false;
          for (let i = 0; i < res.Data.Data.length; i++) {
            const element = res.Data.Data[i];
            element.Between =
                element.Year +
                "岁" +
                element.Month +
                "月" +
                element.Week +
                "周"

                // element.StartYear +
                // "岁" +
                // element.StartMonth +
                // "月" +
                // element.StartWeek +
                // "周" +
                // "-" +
                // element.EndYear +
                // "年" +
                // element.EndMonth +
                // "月" +
                // element.EndWeek +
                // "周";

            for (let j = 0; j < that.WorkTypeData.length; j++) {
              const element2 = that.WorkTypeData[j];
              if (element.WorkType == element2.Id) {
                element.WorkType = element2.Name;
              }
            }
          }

          if(that.pageindex=1){
            that.AllCount = res.Data.AllCount;
          }
          that.tableData = res.Data.Data;
        }
        console.log("that.tableData==>", that.tableData);
      });
    },

    //获取所有教案
    2() {
      let that = this;
      GetAllTeachPlan().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有教案==>", res.Data);

          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];
            element.TeachPlanName =
              element.Year + "岁" + element.Month + "月" + element.Week + "周";
          }
          that.teachData = res.Data;
        }
      });
    },

    //根据条件获取所有教案
    GetRealTeachPlanBySomeFn(row) {
      let that = this;
      console.log("that.form==>", that.form);
      GetRealTeachPlanBySome({
        startYear: that.form.StartYear,
        startMonth: that.form.StartMonth,
        startWeek: that.form.StartWeek,
        endYear: that.form.EndYear,
        endMonth: that.form.EndMonth,
        endWeek: that.form.EndWeek,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("根据条件获取所有教案==>", res.Data);
          that.TeachAnData = res.Data;
          
          // let index = res.Data.findIndex((e) => {
          //   console.log("row.TeachPlan==>", row.TeachPlan);
          //   console.log("e.Id==>", e.Id);
          //   return row.TeachPlan == e.Id;
          // });
          // that.TeachValue = res.Data[index].Id;
          

        } else {
          that.$message.error(res.Message);
        }
      });
    },
  },

  
};
</script>

<style lang="less" scoped>
.bigbox {
  width: 100%;
  .box {
    width: 100%;
    .top {
      width: 100%;
      display: flex;
      margin-bottom: 30px;
      // align-items: center;
      .balance {
        font-size: 16px;
        // text-align: center;
        margin-right: 100px;
        .money {
          display: flex;
          align-items: center;
          height: 24px;
          line-height: 24px;
          .num {
            font-size: 22px;
            // color: #333;
            color: red;
            font-weight: bold;
            .span {
              font-size: 16px;
            }
          }
          .num2 {
            font-size: 22px;
            // color: #333;
            color: red;
            font-weight: bold;
            display: flex;
            align-items: center;
            .span {
              font-size: 16px;
              margin-left: 2px;
            }
          }
        }
        .kamiIpt {
          color: #333;
          margin-top: 5px;
        }
      }
      .balance2 {
        font-size: 16px;
        // text-align: center;
        margin-right: 5px;
        // display: flex;
        // align-items: center;
        .btnBox {
          margin-right: 5px;
          display: flex;
          align-items: center;
          .buy {
            display: flex;
            height: 50px;
            // background-color: palegreen;
            align-items: center;
          }
          .kamiIpt {
            color: #333;
            // margin-top: 5px;
            height: 40px;
          }
          .btn {
            margin-left: 10px;
            // margin-top: 5px;
            margin-right: 10px;
          }
        }
        .endTime {
          margin-top: 5px;
          font-size: 18px;
          .col {
            color: red;
            font-size: 22px;
            font-weight: bold;
          }
          .col2 {
            font-size: 16px;
            color: red;
            font-weight: bold;
          }
        }
      }
      .three {
        margin-top: 10px;
      }
    }
  }
}
</style>